<template>
  <div class="sms-container d-flex flex-column vh-100">
    <div class="d-flex flex-grow-1 overflow-hidden">
      <div class="sidebar col-md-4 p-0 d-flex flex-column border-end bg-light text-dark">
        <div class="search-box p-3 border-bottom">
          <input type="text" class="form-control rounded-pill" placeholder="搜索..." v-model="searchQuery" @input="filterUsers">
        </div>
        <div class="user-list flex-grow-1 overflow-auto">
          <transition-group name="fade" tag="div">
            <div 
              class="user-item d-flex align-items-center p-3 border-bottom animate__animated" 
              v-for="(item, index) in filteredUsers" 
              :key="index"
              @click="selectUser(index)"
              :class="{ active: index === selectedUserIndex }"
            >
              <div class="user-info-container">
                <div class="user-info">
                  <h5 class="mb-0">{{ item.phone }}</h5>
                  <p class="mb-0 text-muted small user-message">{{ item.messages[0]['text'] }}</p>
                </div>
              </div>
              <span class="time ms-auto text-muted small">{{ formatTimestamp(item.messages[0].timestamp) }}</span>
            </div>
          </transition-group>
        </div>
        <div class="p-3">
          <button class="btn btn-primary w-100" @click="showModal = true">添加新对话</button>
        </div>
      </div>
      <div class="chat-box col-md-8 p-0 d-flex flex-column">
        <div class="chat-header p-3 bg-primary text-white border-bottom d-flex align-items-center">
          <img :src="selectedUser.avatar || defaultAvatar" alt="Avatar" class="chat-header-avatar rounded-circle me-3" width="40" height="40">
          <h5 class="mb-0">{{ selectedUser.phone }}</h5>
        </div>
        <div class="chat-messages flex-grow-1 p-3 overflow-auto bg-light">
          <transition-group name="slide" tag="div">
            <div 
              class="message d-flex mb-3 align-items-start" 
              v-for="(message, index) in orderedMessages" 
              :key="index" 
              :class="message.type === 2 ? 'flex-row-reverse' : ''"
            >
              <img :src="message.avatar || defaultAvatar" alt="Avatar" class="message-avatar rounded-circle me-3" width="30" height="30">
              <div :class="['p-3', 'rounded', message.type === 1 ? 'bg-primary text-white' : 'bg-white border']">
                <p class="mb-0">{{ message.text }}</p>
                <small class="text-muted d-block text-end">{{ formatTimestamp(message.timestamp) }}</small>
                <button class="btn btn-link p-0 ms-auto" @click="translateMessage(message, index)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                    <path d="M2 12.5h8v2H5.21c1.14 1.74 2.72 2.6 4.79 2.6 2.12 0 3.8-.87 5.21-2.6H12v-2h8v2h-2.79c-1.25 1.83-3.05 3.26-5.21 4.01v3.49h-2v-3.49c-2.16-.75-3.96-2.18-5.21-4.01H2v-2zm11.5-1.5H10V7h2v1.5h3v2h-1.5z"/>
                  </svg>
                </button>
                <p v-if="message.translatedText" class="mt-2 mb-0">{{ message.translatedText }}</p>
              </div>
            </div>
          </transition-group>
        </div>
        <div class="chat-input p-3 bg-light border-top d-flex">
          <input 
            v-model="newMessage" 
            @keyup.enter="sendMessage" 
            type="text" 
            class="form-control rounded-pill me-3" 
            placeholder="输入消息..."
          />
          <button class="btn btn-primary rounded-pill" @click="sendMessage">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
              <path d="M2 21l21-9L2 3v7l15 2-15 2v7z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- 添加新对话 Modal -->
    <b-modal v-model="showModal" title="添加新对话" hide-footer centered>
      <template v-slot:modal-header>
        <h5 class="modal-title">添加新对话</h5>
        <button type="button" class="btn-close" @click="showModal = false"></button>
      </template>
      <div class="form-group">
        <label for="phoneNumber">手机号码</label>
        <input type="text" class="form-control rounded-pill" id="phoneNumber" v-model="newUser.phone" placeholder="输入手机号码">
      </div>
      <div class="form-group mt-3">
        <label for="messageContent">短信内容</label>
        <textarea class="form-control rounded" id="messageContent" v-model="newUser.message" rows="3" placeholder="输入短信内容"></textarea>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <button class="btn btn-secondary rounded-pill me-2" @click="showModal = false">取消</button>
        <button class="btn btn-primary rounded-pill" @click="addNewConversation">添加</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import axios from 'axios';
import { get, post } from '@/axios/index';

export default {
  name: 'KeySms',
  components: {
    BModal
  },
  data() {
    return {
      users: [],
      filteredUsers: [],
      selectedUserIndex: null,
      selectedUser: {
        phone: '',
        messages: []
      },
      newMessage: '',
      newUser: {
        phone: '',
        message: ''
      },
      showModal: false,
      userPage: 1,
      messagePage: 1,
      defaultAvatar: require('@/assets/logo.png'),
      searchQuery: '',
      ws: null
    };
  },
  computed: {
    orderedMessages() {
      if (this.selectedUser && this.selectedUser.messages) {
        return this.selectedUser.messages.slice().sort((a, b) => a.timestamp - b.timestamp);
      }
      return [];
    }
  },
  methods: {
    formatTimestamp(timestamp) {
      if (!timestamp) return '';
      const date = new Date(timestamp * 1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    async fetchUsers() {
      const response = await get('/v3/SmsChat');
      console.log(response)
      if(response.status == 400){
        this.$notify({
            title: response.message,
            message: response.message,
            type: 'success'
        });
        this.$router.push('/'); // 假设登录后跳转到主页
      }
      this.users = response.data;
      this.filteredUsers = this.users;
      if (this.filteredUsers.length > 0) {
        this.selectUser(0); // 默认选择第一个用户
      }
    },
    filterUsers() {
      const query = this.searchQuery.toLowerCase();
      this.filteredUsers = this.users.filter(user => user.phone.toLowerCase().includes(query));
    },
    selectUser(index) {
      this.selectedUserIndex = index;
      this.selectedUser = this.filteredUsers[index];
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    sendMessage() {
      if (this.newMessage.trim()) {
        const newMessage = {
          id: Date.now(),
          text: this.newMessage,
          timestamp: Math.floor(Date.now() / 1000),
          type: 2,
          avatar: this.defaultAvatar
        };
        this.selectedUser.messages.push(newMessage);
        this.newMessage = '';
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    },
    addNewConversation() {
      if (this.newUser.phone.trim() && this.newUser.message.trim()) {
        const phones = this.newUser.phone.split(',').map(phone => phone.trim());
        this.sendMessagesSequentially(phones, this.newUser.message);
        this.newUser = { phone: '', message: '' };
        this.showModal = false;
      }
    },
    async sendMessagesSequentially(phones, message) {
      for (const phone of phones) {
        const existingUserIndex = this.users.findIndex(user => user.phone === phone);
        if (existingUserIndex !== -1) {
          this.users[existingUserIndex].messages.push({
            id: Date.now(),
            text: message,
            timestamp: Math.floor(Date.now() / 1000),
            type: 1
          });
        } else {
          const newUser = {
            id: Date.now(),
            phone: phone,
            avatar: this.defaultAvatar,
            messages: [{ id: Date.now(), text: message, timestamp: Math.floor(Date.now() / 1000), type: 1 }]
          };
          this.users.push(newUser);
        }

        try {
          await this.sendMessageToPhone(message, phone);
        } catch (error) {
          console.error('发送失败:', error);
        }
      }
    },
    async sendMessageToPhone(message, phone) {
      const response = await post('/v3/SmsSend', { text: message, phone: phone.replace('+', '')});
      console.log(response);
    },
    async translateMessage(message, index) {
      try {
        const response = await axios.post('https://libretranslate.de/translate', {
          q: message.text,
          source: 'en',
          target: 'zh',
          format: 'text'
        });
        this.$set(this.selectedUser.messages[index], 'translatedText', response.data.translatedText);
      } catch (error) {
        console.error('翻译失败:', error);
      }
    },
    scrollToBottom() {
      const chatMessages = this.$el.querySelector('.chat-messages');
      chatMessages.scrollTop = chatMessages.scrollHeight;
    },
    connectWebSocket() {
      this.ws = new WebSocket('wss://smsim.yy-nuoya.com:9090');
      this.ws.onopen = () => {
        this.ws.send(JSON.stringify({ key: localStorage.getItem('token') }));
      };
      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // 处理接收到的消息
        const userIndex = this.users.findIndex(user => user.phone === data.phone);
        if (userIndex !== -1) {
          const existingMessageIndex = this.users[userIndex].messages.findIndex(msg => msg.id === data.message.id);
          if (existingMessageIndex === -1) {
            this.$set(this.users[userIndex].messages, this.users[userIndex].messages.length, data.message);
          }
        }
      };
      this.ws.onclose = () => {
        console.log('WebSocket disconnected');
      };
    }
  },
  async mounted() {
    await this.fetchUsers();
    if (this.filteredUsers.length > 0) {
      this.selectUser(0); // 默认选择第一个用户
    }
    this.scrollToBottom();
    if (localStorage.getItem('token')) {
      this.connectWebSocket();
    }
  }
};
</script>

<style scoped>
.user-info-container {
  display: flex;
  align-items: center;
}

.user-info {
  flex: 1;
  overflow: hidden;
}

.user-info h5 {
  margin-bottom: 0;
}

.user-info p {
  margin-bottom: 0;
  color: #6c757d;
  font-size: 14px;
  width: 150px; /* 可以根据需要调整 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sms-container {
  height: 100vh;
  background-color: #f0f2f5;
  font-family: 'Arial', sans-serif;
}

.bg-light {
  background-color: #ffffff !important;
}

.sidebar {
  border-right: 1px solid #dee2e6;
}

.search-box input {
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
}

.user-list {
  flex-grow: 1;
  overflow-y: auto;
}

.user-item {
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  color: #000;
}

.user-item:hover, .user-item.active {
  background: rgba(0, 123, 255, 0.1);
  color: #000;
}

.avatar {
  width: 50px;
  height: 50px;
}

.chat-box {
  display: flex;
  flex-direction: column;
}

.chat-header {
  background-color: #007bff;
  color: #fff;
}

.chat-messages {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

.message {
  display: flex;
  margin-bottom: 15px;
}

.message-sent {
  margin-left: auto;
  background-color: #007bff;
  color: white;
  border-radius: 15px 15px 0 15px;
  padding: 10px;
}

.message-received {
  background-color: #ffffff;
  border-radius: 15px 15px 15px 0;
  border: 1px solid #dee2e6;
  padding: 10px;
}

.chat-input {
  background-color: #f8f9fa;
}

.chat-input input {
  border: 1px solid #ced4da;
}

.chat-input button {
  border: none;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  margin: 0;
}

.btn-close {
  background: none;
  border: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.slide-enter-active {
  animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
