import axios from 'axios';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API || 'https://smsim.yy-nuoya.com', // 你的 API 基础地址
  timeout: 5000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 从本地存储中获取 token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response && error.response.data) {
      if (error.response.data.status == 400) {
        // 处理令牌过期的逻辑，例如跳转到登录页
        localStorage.removeItem('token');
        window.location.href = '/login'; // 修改为你的登录页路径
      }
    }
    return Promise.reject(error);
  }
);

export function get(url, params) {
  return service.get(url, { params });
}

export function post(url, data) {
  return service.post(url, data);
}

export default service;
