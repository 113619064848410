<template>
  <div class="login-container">
    <div class="login-box">
      <div class="i18n"></div>
      <div class="login-left"></div>
      <div class="login-form">
        <div class="login-logo">
          <img src="@/assets/logo.png" alt="精聊平台">
        </div>
        <h2 class="login-title">精聊平台</h2>
        <form @submit.prevent="onSubmit" class="form">
          <div class="form-item">
            <input v-model="loginForm.password" type="password" placeholder="密码" required>
          </div>
          <div class="form-item">
            <button type="submit" class="primary">登录</button>
          </div>
        </form>
      </div>
      <div class="footer"></div>
    </div>
  </div>
</template>

<script>
import { post } from '@/axios/index';

export default {
  name: 'KeyLogin',
  data() {
    return {
      loginForm: {
        password: ''
      }
    };
  },
  methods: { 
    async onSubmit() {
      try {
        const response = await post('/v2/login', { key: this.loginForm.password });
        console.log(response)
        if (response.status == 200) {
          localStorage.setItem('token', response.data.token);
          this.$notify({
            title: '成功',
            message: response.message,
            type: 'success'
          });
          this.$router.push('/sms'); // 假设登录后跳转到主页
        } else {
          this.$notify({
            title: '失败',
            message: response.message || '登录失败',
            type: 'error'
          });
        }
      } catch (error) {
        this.$notify({
          title: '错误',
          message: error.message || '登录过程中发生错误',
          type: 'error'
        });
      }
    },
    onReset() {
      this.loginForm.password = '';
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('@/assets/login.svg') no-repeat center center;
  background-size: cover;
}

.login-box {
  display: flex;
  align-items: center;
  background-color: rgb(252, 247, 247);
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  height: 90%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.i18n {
  align-self: flex-end;
}

.login-left {
  width: 100%;
  height: 100%;
  background: url('@/assets/bg-image.png') no-repeat center center;
  background-size: contain;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

.login-logo img {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-item {
  margin-bottom: 20px;
}

.form-item input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.form-item button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-item button.primary {
  background-color: #409eff;
  color: white;
}

.form-item button:hover {
  opacity: 0.9;
}

.footer {
  margin-top: 20px;
}
</style>
